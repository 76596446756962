








































































import Vue from 'vue';
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {Network, Node} from '@stellarbeat/js-stellar-domain';
import CrawlTime from '@/components/crawl-time.vue';
import Store from '@/store/Store';
import SimulationBadge from '@/components/simulation-badge.vue';
import NodesTable from '@/components/node/nodes-table.vue';
import TimeTravelBadge from '@/components/time-travel-badge.vue';
import {BFormInput} from 'bootstrap-vue';
import {StoreMixin} from '@/mixins/StoreMixin';

@Component({
    components: {TimeTravelBadge, NodesTable, SimulationBadge, CrawlTime, BFormInput: BFormInput},
    metaInfo: {
        title: 'Nodes overview - Stellarbeat.io',
        meta: [
            {name: 'description', content: 'Search through all available nodes'},
        ],
    },
})
export default class Nodes extends Mixins(StoreMixin) {
    @Prop()
    public isLoading!: boolean;

    public optionShowInactive: boolean = false;
    public optionShowWatchers: boolean = false;
    public filter: string = '';

    get fields(): any {
        if (this.store.isSimulation) {
            return [{key: 'name', sortable: true},
                {key: 'organization', sortable: true},
                {key: 'type', label: 'type', sortable: true}];
        } else {
            return [
                {key: 'name', sortable: true},
                {key: 'organization', sortable: true},
                {key: 'type', label: 'type', sortable: true},
                // { key: 'publicKey', label: 'Public key (first 20 characters)', sortable: true },
                {key: 'active24Hour', label: '24H active', sortable: true},
                {key: 'active30Days', label: '30D active', sortable: true},
                {key: 'validating24Hour', label: '24H validating', sortable: true},
                {key: 'validating30Days', label: '30D validating', sortable: true},
                {key: 'overLoaded24Hour', label: '24H overloaded', sortable: true},
                {key: 'index', label: 'index', sortable: true},
                {key: 'validating', sortable: true},
                {key: 'version', sortable: true},
                {key: 'country', sortable: true},
                {key: 'ip', sortable: true},

                {key: 'actions', label: ''},
            ];
        }


    }

    get nodes(): any[] {
        return this.network.nodes
            .filter((node) => node.active || this.optionShowInactive)
            .filter((node) => node.isValidator || this.optionShowWatchers)
            .map((node) => {
                return {
                    name: node.displayName,
                    type: node.isFullValidator ? 'Full validator' : node.isValidator ? 'Validator' : 'Watcher',
                    active24Hour: node.statistics.has24HourStats ? node.statistics.active24HoursPercentage + '%' : 'NA',
                    active30Days: node.statistics.has30DayStats ? node.statistics.active30DaysPercentage + '%' : 'NA',
                    validating24Hour: node.statistics.has24HourStats ? node.statistics.validating24HoursPercentage + '%' : 'NA',
                    validating30Days: node.statistics.has30DayStats ? node.statistics.validating30DaysPercentage + '%' : 'NA',
                    overLoaded24Hour: node.statistics.has24HourStats ? node.statistics.overLoaded24HoursPercentage + '%' : 'NA',
                    ip: node.key,
                    publicKey: node.publicKey,
                    country: node.geoData.countryName,
                    version: node.versionStr,
                    isFullValidator: node.isFullValidator,
                    isValidator: node.isValidator,
                    index: node.index,
                    validating: node.isValidating,
                    organization: this.getOrganization(node),
                    organizationId: node.organizationId
                };
            });
    }

    getOrganization(node: Node): string {
        if (!node.organizationId) {
            return '-';
        }
        let organization = this.network.getOrganizationById(node.organizationId);
        if (organization) {
            return organization.name;
        } else {
            return '-';
        }
    }
}
